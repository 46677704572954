import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";
import { SVGs } from "../assets/svg";
import Statslight from "../assets/svg/StatsLight.svg";
import Logo from "../assets/svg/HeaderLogo.svg";
import HowToBuyGear from "../assets/svg/HowToBuyGear.svg";

// Animation variants
const headerVariants = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: "easeOut" } },
};

const cardVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: { delay: i * 0.2, duration: 0.6, ease: "easeOut" },
  }),
};

const scrollingIconsVariants = {
  animate: {
    x: [0, -1000],
    transition: { repeat: Infinity, duration: 10, ease: "linear" },
  },
};

const HowToBuyCard = ({ item, index }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, {
    margin: "-30% 0px -40% 0px",
    once: false,
  });

  return (
    <motion.div
      ref={ref}
      className={`howtobuy-card flex items-stretch gap-6 px-8 md:w-auto w-full md:justify-start justify-center ${
        isInView && window.innerWidth <= 768 ? "htb-card-hover-active" : ""
      }`}
      custom={index}
      variants={cardVariants}
      transition={{ duration: 0.3 }}
    >
      <div className="relative p-6 flex-shrink-0">
        <img
          alt="stats-light"
          src={Statslight}
          className="absolute top-0 w-full left-1/2 transform -translate-x-1/2"
        />
        <SVGs.WalletSVG />
      </div>
      <p className="sm:text-[26px] text-[20px] font-extrabold text-white leading-tight md:whitespace-nowrap flex items-center">
        {item.text}
      </p>
    </motion.div>
  );
};

const HowToBuy = () => {
  const steps = [
    { text: "Copy Contract Address" },
    { text: "Visit Decentralized Exchange" },
    { text: "Swap $SUDL Token" },
  ];

  return (
    <div className="relative flex flex-col w-full items-center justify-between gap-4 px-4 md:px-8 lg:px-16 py-8 md:py-16">
      <img
        alt="howtobuy-gear"
        src={HowToBuyGear}
        className="absolute left-0 top-0 translate-x-[25%] md:translate-x-[100%] lg:translate-y-[0] translate-y-[-25%] sm:scale-100 scale-75"
      />

      {/* Header Section */}
      <motion.p
        className="text-[13px] font-semibold text-[#13fb73]"
        initial="hidden"
        animate="visible"
        variants={headerVariants}
      >
        How to buy
      </motion.p>

      <motion.p
        className="text-[32px] font-semibold text-transparent bg-clip-text bg-gradient-to-r text-center leading-tight"
        initial="hidden"
        animate="visible"
        variants={headerVariants}
      >
        Simple Steps to Acquire $SUDL
      </motion.p>

      <motion.p
        className="text-[14px] font-normal text-center text-[#c4efea]"
        initial="hidden"
        animate="visible"
        variants={headerVariants}
      >
        Follow the steps below to purchase $SUDL tokens with ease and start your
        investment journey today
      </motion.p>

      {/* Card Container */}
      <motion.div
        className="flex flex-wrap justify-center items-start gap-4 w-full max-w-[1000px] mx-auto"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.3 }}
      >
        {steps.map((item, index) => (
          <HowToBuyCard key={index} item={item} index={index} />
        ))}
      </motion.div>

      {/* Scrolling Icons */}
      <div className="w-full overflow-hidden py-4 relative mt-12">
        <motion.div
          className="scrolling-icons flex items-center"
          variants={scrollingIconsVariants}
          animate="animate"
        >
          {[...Array(20)].map((_, index) => (
            <img key={index} src={Logo} alt="Scrolling Icon" className="mx-6" />
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default HowToBuy;
